import styled from 'styled-components';
import tw, { theme } from 'twin.macro';

export const Wrap = styled.div`
    ${tw`py-24 text-lg  text-gray-800`};
  p {
    ${tw`mt-2 leading-loose`};
  }
  h1 {
    ${tw`text-lg md:text-3xl font-bold my-5 break-words`};
  }
  h2 {
    ${tw`text-lg md:text-2xl font-bold my-4 break-words`};
  }
  h3 {
    ${tw`text-lg md:text-xl font-bold my-3 break-words`};
  }
  h4 {
    ${tw`text-lg md:text-xl font-bold my-3 break-words `};
  }
  ul {
    ${tw`flex flex-col list-none  my-3`};
    li {
      ${tw`ml-2 mb-3 text-sm`};
      h3 {
     ${tw`text-lg font-bold mt-6`};
     }
      p {
        ${tw`mt-0 inline leading-normal`};
      }
      ul {
         ${tw`flex flex-col list-disc  my-6 pl-4`};
      }
    }
  }
  a{
    ${tw`text-primaryPurple underline`};

  }
`;
