import React from 'react';
import { graphql } from 'gatsby';
import parse from "html-react-parser";
import * as S from "./markdownPageTemplate.styled"

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
    path,
}) {
    const { markdownRemark } = data; // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark;
    return (

        <S.Wrap>{parse(html)}</S.Wrap>

    );
}

export const pageQuery = graphql`
    query($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                slug
                title
            }
        }
    }
`;
